import React, { useState, useEffect } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { MdFileDownloadDone } from "react-icons/md";
import { FaTimes, FaBars } from 'react-icons/fa';
import axios from 'axios';
import './Home.css';

const sections = [
    {
        title: "THE PROBLEM",
        content: `<p>In developing and restructuring land and property, 
                sustainability subjects translate into regulatory 
                guidelines to follow and certifications to be obtained. 
                But Climate change risks, especially those linked to water, 
                impact economically on the value and long-term profitability 
                of the project. What's missing from the market is an effective 
                system of data analysis that also provides actionable insights, 
                and that does not require high-level technical skills to be operated.</p>`,
        imgUrlJpg: '/the-problem.jpg',
        imgUrlAvif: '/the-problem.avif',
        imgUrlWebp: '/the-problem.webp'
    },
    {
        title: "HOW WE SOLVE IT",
        content: `
                <p>We deliver precise and seamless insights on the nature of
                    the land and its existing urbanization. We then provide
                    you with: 
                </p>
                <ul>
                    <li>Evaluation of its <b> maximum value potential </b></li>
                    <li>Environmental impact, starting with <b> water: </b> damage,
                        scarcity, quality, rights and compliancy, and all possible
                        risks linked to development in that area.
                    </li>
                </ul>
                <p>With our distinctive and proprietary geospatial satellite
                    technology and platform, <b> we allow maximization of yield
                    on R.E. investments, providing: </b>
                </p>
                <ul>
                    <li><b>Gold standard ratings</b></li>
                    <li><b>Actionable Mitigation Plan</b></li>
                </ul>
                `,
        imgUrlJpg: '/how-we-solve.jpg',
        imgUrlAvif: '/how-we-solve.avif',
        imgUrlWebp: '/how-we-solve.webp'
    },
    {
        title: "OUR PLAN",
        content: `<p>We aim at dismantling complexity through our distinctive
                proprietary geospatial satellite technology and platform,
                that leverage and integrate sustainability and climate
                change insights, risk assessments, economic impacts and
                easy to understand data.</p>`,
        imgUrlJpg: 'our-plan.jpg',
        imgUrlAvif: 'our-plan.avif',
        imgUrlWebp: 'our-plan.webp'
    },
];

const values = [
    {
        title: "PEOPLE BEFORE TECHNOLOGY",
        description: "FinSat ensures that its tools empower communities worldwide to rebuild stronger, live peacefully, and create a legacy for future generations."
    },
    {
        title: "FAIL TEST",
        description: "By learning fast and making action happen in real life, FinSat encourages rapid learning and real-world action."
    },
    {
        title: "CONNECTING PEOPLE, INNOVATION AND TECHNOLOGY",
        description: "By creating strong relationships and learning from each other, FinSat creates a foundation of trust and mutual respect."
    },
    {
        title: "TRANSPARENT DIPLOMACY",
        description: "By respecting and understanding cultural needs, FinSat builds trust-based and effective collaborations."
    },
    {
        title: "HONESTY, OPEN DIALOGUE AND LOYALTY",
        description: "By building strong relationships and learning from each other, FinSat creates a foundation of trust and mutual respect."
    }
]

const Home = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [error, setError] = useState(false);

    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [subscribeEmailError, setSubscribeEmailError] = useState(false);
    const [subscribeError, setSubscribeError] = useState(false);

    function isValidEmail(email) {
        return /^[A-zÀ-ž0-9][A-z0-9À-ž._%+-]+@[A-z0-9À-ž._%+-]+.[a-z]{2,4}/.test(email);
    }

    function isValidUSPhoneNumber(phoneNumber) {
        return /^[^A-Za-z]+$/.test(phoneNumber);
    }

    const firstNameHandleChange = event => {
        setFirstName(event);

        if (!event) {
            setFirstNameError('Field is required!');
        } else {
            setFirstNameError('');
        }
    };

    const lastNameHandleChange = event => {
        setLastName(event);

        if (!event) {
            setLastNameError('Field is required!');
        } else {
            setLastNameError('');
        }
    };

    const emailHandleChange = event => {
        setEmail(event);

        if (!isValidEmail(event)) {
            setEmailError('Email is invalid!');
        } else {
            setEmailError('');
        }
    };

    const phoneNumberHandleChange = event => {
        setPhoneNumber(event);

        if (!isValidUSPhoneNumber(event)) {
            setPhoneNumberError('Phone number is invalid!');
        } else {
            setPhoneNumberError('');
        }
    };

    const messageHandleChange = event => {
        setMessage(event);

        if (!event) {
            setMessageError('Field is required!');
        } else {
            setMessageError('');
        }
    };

    const handleSubmitMessage = () => {
        const body = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            message: message,
        }

        axios.post('https://api.finsat.space/api/contact-us', body)
            .then(() =>
                console.log(''),
                setFirstName(''),
                setLastName(''),
                setEmail(''),
                setPhoneNumber(''),
                setMessage(''),
                setError(true),
                setTimeout(() => {
                    setError(false)
                }, 7000)
            ).catch(error => console.log(error))
    }

    const subscribeEmailHandleChange = event => {
        setSubscribeEmail(event);

        if (!isValidEmail(event)) {
            setSubscribeEmailError('Email is invalid!');
        } else {
            setSubscribeEmailError('');
        }
    };

    const handleSubscribe = () => {
        const body = {
            email: subscribeEmail,
        }

        axios.post('https://api.finsat.space/api/newsletter/subscribe', body)
            .then(() =>
                console.log(''),
                setSubscribeEmail(''),
                setSubscribeError(true),
                setTimeout(() => {
                    setSubscribeError(false)
                }, 7000)
            ).catch(error => console.log(error))
    }

    useEffect(() => {
        const whatWeDoLink = document.getElementById('what-we-do-link');
        const productLink = document.getElementById('product-link');
        const missionLink = document.getElementById('mission-link');
        const valuesLink = document.getElementById('values-link');
        const contactLink = document.getElementById('contact-link');

        whatWeDoLink.classList.add('all_section');
        productLink.classList.add('all_section');
        missionLink.classList.add('all_section');
        valuesLink.classList.add('all_section');
        contactLink.classList.add('all_section');

        const handleScroll = () => {
            const whatWeDoSection = document.getElementById('what-we-do');
            const productSection = document.getElementById('product');
            const missionSection = document.getElementById('mission');
            const valuesSection = document.getElementById('values');
            const contactSection = document.getElementById('contact');
            const subscribeSection = document.getElementById('subscribe');

            const whatWeDoSectionTop = whatWeDoSection.getBoundingClientRect().top;
            const productSectionTop = productSection.getBoundingClientRect().top;
            const missionSectionTop = missionSection.getBoundingClientRect().top;
            const valuesSectionTop = valuesSection.getBoundingClientRect().top;
            const contactSectionTop = contactSection.getBoundingClientRect().top;
            const subscribeSectionTop = subscribeSection.getBoundingClientRect().top;

            if (whatWeDoSectionTop <= 80 && productSectionTop >= 80) {
                whatWeDoLink.classList.add('current_section');
                productLink.classList.remove('current_section');
                missionLink.classList.remove('current_section');
                valuesLink.classList.remove('current_section');
                contactLink.classList.remove('current_section');
            } else if (productSectionTop <= 80 && missionSectionTop >= 80) {
                whatWeDoLink.classList.remove('current_section');
                productLink.classList.add('current_section');
                missionLink.classList.remove('current_section');
                valuesLink.classList.remove('current_section');
                contactLink.classList.remove('current_section');
            } else if (missionSectionTop <= 80 && valuesSectionTop >= 80) {
                whatWeDoLink.classList.remove('current_section');
                productLink.classList.remove('current_section');
                missionLink.classList.add('current_section');
                valuesLink.classList.remove('current_section');
                contactLink.classList.remove('current_section');
            } else if (valuesSectionTop <= 80 && contactSectionTop >= 80) {
                whatWeDoLink.classList.remove('current_section');
                productLink.classList.remove('current_section');
                missionLink.classList.remove('current_section');
                valuesLink.classList.add('current_section');
                contactLink.classList.remove('current_section');
            } else if (contactSectionTop <= 80 && subscribeSectionTop >= 80) {
                whatWeDoLink.classList.remove('current_section');
                productLink.classList.remove('current_section');
                missionLink.classList.remove('current_section');
                valuesLink.classList.remove('current_section');
                contactLink.classList.add('current_section');
            } else {
                whatWeDoLink.classList.remove('current_section');
                productLink.classList.remove('current_section');
                missionLink.classList.remove('current_section');
                valuesLink.classList.remove('current_section');
                contactLink.classList.remove('current_section');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const navigateToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            // Alternatively, use window.location.hash :
            // window.location.hash = `#${sectionId}`;
        }
    }

    return (
        <>
            {/* Header section */}
            <header className='header'>
                <div className='header_container'>
                    <div className='logo'>
                        <img src="/logo.png" alt="FinSat Logo" />
                    </div>
                    <div className={click ? 'nav_links_button active' : 'nav_links_button'}>
                        <nav className='nav_links'>
                            <a id='what-we-do-link' href="#what-we-do" onClick={closeMobileMenu}>WHAT WE DO</a>
                            <a id='product-link' href="#product" onClick={closeMobileMenu}>PRODUCT</a>
                            <a id='mission-link' href="#mission" onClick={closeMobileMenu}>MISSION</a>
                            <a id='values-link' href="#values" onClick={closeMobileMenu}>VALUES</a>
                            <a id='contact-link' href="#contact" onClick={closeMobileMenu}>CONTACT US</a>
                        </nav>
                        {/* <div className='cta_button'>
                            <a href='https://app.finsat.space/register' target="_blank" rel="noreferrer"><button>SIGN UP</button></a>
                        </div> */}
                    </div>
                    <div className='menu-icon' onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                </div>
            </header>
            {/* Banner section */}
            <div className='home_background'>
                <div className='home'>
                    <div className='home_title'>
                        <h1>UNLOCK ALL THE VALUE BEHIND CLIMATE CHANGE IMPACT</h1>
                        <p>FinSat is the profitable way to develop land and properties
                            sustainably, to make smart Real Estate decisions and mitigate risk,
                            increasing the value of land and property investments. Transform
                            Climate Risk into Development Opportunity with CityL3ns: AI-Powered
                            Weather Intelligence Platform.</p>
                        <div className='button_contact_us'>
                            <a href='https://app.finsat.space/register' target="_blank" rel="noreferrer"><button>JOIN OUR WAITLIST</button></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* What we do section */}
            <div id='what-we-do' className='what_we_do'>
                <div className='section_title_container'>
                    <p className={`section_title ${activeIndex === 0 ? 'active' : ''}`}>THE PROBLEM</p>
                    <p className={`section_title ${activeIndex === 1 ? 'active' : ''}`}>HOW WE SOLVE IT</p>
                    <p className={`section_title ${activeIndex === 2 ? 'active' : ''}`}>OUR PLAN</p>
                </div>
                <div className='dots'>
                    <div className='line'></div>
                    {sections.map((section, index) => (
                        <>
                            <div
                                key={index}
                                className={`dot ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => setActiveIndex(index)}
                            >
                            </div>
                        </>
                    ))}
                </div>
                <div className='content'>
                    <div className='content_image'>
                        <picture>
                            <source srcSet={sections[activeIndex].imgUrlWebp} type="image/webp" />
                            <source srcSet={sections[activeIndex].imgUrlAvif} type="image/avif" />
                            <img src={sections[activeIndex].imgUrlJpg} alt="Mission image" />
                        </picture>
                        {/* <img alt='What we do' src={sections[activeIndex].imgUrl} /> */}
                    </div>
                    <div className='content_description'>
                        <h5>WHAT WE DO</h5>
                        <h2>{sections[activeIndex].title}</h2>
                        <div className='content_text' dangerouslySetInnerHTML={{ __html: sections[activeIndex].content }} />
                    </div>
                </div>
            </div>
            {/* Product section */}
            <div id='product' className='product_container'>
                <div className='product_content'>
                    <div className='product_description'>
                        <h5>THE PRODUCT</h5>
                        <h2>CityL3ns</h2>
                        <p>Our interactive GIS platform, provides an interactive map
                            with vital information for decision making.
                            With CityL3ns, we show you the land and property now,
                            while getting you real time predictive modeling for the
                            environment of a region and its Climate Change and water
                            impacts for the future.
                        </p>
                        <div className='product_get_demo'>
                            <a href='https://app.finsat.space/register' target="_blank" rel="noreferrer"><button>SIGN UP</button></a>
                        </div>
                    </div>
                    <div className='product_image'>
                        <picture>
                            <source srcSet="product.webp" type="image/webp" />
                            <source srcSet="product.avif" type="image/avif" />
                            <img src="product.jpg" alt="Product image" />
                        </picture>
                        {/* <img alt='Product image' src='/product.png' /> */}
                    </div>
                </div>
            </div>
            {/* Mission section */}
            <div id='mission' className='mission'>
                <div className='mission_image'>
                    <picture>
                        <source srcSet="mission.webp" type="image/webp" />
                        <source srcSet="mission.avif" type="image/avif" />
                        <img src="mission.jpg" alt="Mission image" />
                    </picture>
                    {/* <img alt='Mission image' src='/mission.png' /> */}
                </div>
                <div className='mission_description'>
                    <h5>MISSION</h5>
                    <div className='mission_description_text'>
                        <h2>WHAT WE STAND FOR</h2>
                        <p>FinSat's purpose is to be the leading authority in
                            releasing the value of climate change impacts on
                            land and properties.
                            With our commitment to sustainability, we stand for
                            building more valuable properties and stronger,
                            peaceful communities for future generations.
                            We prioritize people over technology and bring
                            individuals together to solve the toughest challenges
                            and give them access to insights of the world's most
                            essential resources.
                        </p>
                    </div>
                    <div className='mission_description_text'>
                        <h2>HOW WE OPERATE</h2>
                        <p>We provide comprehensive Real Estate Climate
                            Impact Assessments and trends, also in key resources
                            like water drought and flood vulnerability, delivering
                            actionable mitigation opportunities, and transition
                            based ratings, to optimize property value solutions.
                        </p>
                        <p className='operate_description'>We integrate advanced space and in-situ data with
                            IoT systems to monitor, and proprietary AI to predict
                            future trends, ensuring our solutions are verified,
                            regenerative and forward-thinking.
                        </p>
                    </div>
                </div>
            </div>
            {/* Our values section */}
            <div id='values' className='our_values_container'>
                <div className='our_values'>
                    <h5 className='our_values_section_title'>OUR VALUES</h5>
                    <div className='values_container'>
                        {values.map((value, index) => (
                            <div className='value_card' key={index}>
                                <h4 className='value_title'>{value.title}</h4>
                                <p className='value_description'>{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Contact us section */}
            <div id='contact' className='contact_us_container'>
                <div className='contact_us_content'>
                    <h5>CONTACT US</h5>
                    <h2 className='message_us_title'>ANY QUESTIONS</h2>
                    <form className='form'>
                        <div className='form_container'>
                            <div className='form_right'>
                                <label>First Name*</label>
                                <input type='text' value={firstName} onChange={(e) => firstNameHandleChange(e.target.value)} placeholder='First Name' required />
                                {firstNameError && <span style={{ color: 'red', marginTop: -25, fontSize: 14 }}>{firstNameError}</span>}
                            </div>
                            <div className='form_right'>
                                <label>Last Name*</label>
                                <input type='text' value={lastName} onChange={(e) => lastNameHandleChange(e.target.value)} placeholder='Last Name' required />
                                {lastNameError && <span style={{ color: 'red', marginTop: -25, fontSize: 14 }}>{lastNameError}</span>}
                            </div>
                        </div>
                        <div className='text_area'>
                            <label>Email*</label>
                            <input type='email' value={email} onChange={(e) => emailHandleChange(e.target.value)} placeholder='you@email.com' pattern="^[A-zÀ-ž0-9][A-z0-9À-ž._%+-]+@[A-z0-9À-ž._%+-]+.[a-z]{2,4}$" required />
                            {emailError && <span style={{ color: 'red', marginTop: -25, fontSize: 14 }}>{emailError}</span>}
                        </div>
                        <div className='text_area'>
                            <label>Phone number*</label>
                            <input type='tel' value={phoneNumber} onChange={(e) => phoneNumberHandleChange(e.target.value)} placeholder='+1 (555) 000-000' pattern="^[A-zÀ-ž0-9][A-z0-9À-ž._%+-]+@[A-z0-9À-ž._%+-]+.[a-z]{2,4}$" required />
                            {phoneNumberError && <span style={{ color: 'red', marginTop: -25, fontSize: 14 }}>{phoneNumberError}</span>}
                        </div>
                        <div className='text_area'>
                            <label>Message*</label>
                            <textarea rows="5" value={message} onChange={(e) => messageHandleChange(e.target.value)} placeholder='Leave us a message...' required></textarea>
                            {messageError && <span style={{ color: 'red', marginTop: 5, fontSize: 14 }}>{messageError}</span>}
                        </div>
                        {error &&
                            <div className='success_message'>
                                <MdFileDownloadDone className='icon_succsess' /> <p>Successfully sent, thank you for contacting us!</p>
                            </div>
                        }
                        <div className='send_message'>
                            <button type='button' onClick={handleSubmitMessage}
                                style={{ backgroundColor: !firstName || !lastName || !email || !phoneNumber || !message || !!firstNameError || !!lastNameError || !!emailError || !!phoneNumberError || !!messageError ? '#68b6ff' : '#1C88EC' }}
                                disabled={!firstName || !lastName || !email || !phoneNumber || !message || !!firstNameError || !!lastNameError || !!emailError || !!phoneNumberError || !!messageError}
                            >SEND MESSAGE</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Subscribe section */}
            <div id='subscribe' className='subscribe_background'>
                <div className='subscribe'>
                    <h1>Subscribe now to get company news.</h1>
                    <form className='subscribe_input_button'>
                        <input type='email' value={subscribeEmail} onChange={(e) => subscribeEmailHandleChange(e.target.value)} placeholder='Enter your Email' required />
                        {subscribeEmailError && <span style={{ color: '#fff', marginTop: -25, marginLeft: 20, fontSize: 14 }}>{subscribeEmailError}</span>}
                        <button type='button' onClick={handleSubscribe}>SUBSCRIBE</button>
                    </form>
                </div>
            </div>
            {/* Footer section */}
            <div className='footer_container'>
                <div className='footer_content'>
                    <img alt='Finsat logo' src='/logo.png' />
                    <p>FinSat is the profitable way to develop land and properties
                        sustainably, to make smart Real Estate decisions and mitigate risk,
                        increasing the value of land and property investments.
                    </p>
                    <div className='social_media'>
                        <a href='https://www.linkedin.com/company/finsat/' target="_blank" rel="noreferrer"><img alt='Linkedin logo' src='/linkedin.png' /></a>
                        <a href='https://x.com/FinSat_Space' target="_blank" rel="noreferrer"><img alt='Twitter logo' src='/twitter.png' /></a>
                        <a href='https://www.instagram.com/finsat_space_?igsh=MXIybDR3YmFjYTZlZw==' target="_blank" rel="noreferrer"><img alt='Instagram logo' src='/instagram.png' /></a>
                    </div>
                </div>
                <div className='footer_contact_menu'>
                    <div className='footer_contact'>
                        <h3>Main Office</h3>
                        <div className='footer_contact_description_location'>
                            <IoLocationSharp className='location' />
                            <p>New York, New York</p>
                        </div>
                        <div className='footer_contact_description_email'>
                            <IoIosMail className='email' />
                            <a href='mailto: info@finsat.space'>info@finsat.space</a>
                        </div>
                    </div>
                    <div className='footer_menu'>
                        <h3>Quick links</h3>
                        <p onClick={() => navigateToSection('what-we-do')}>What We Do</p>
                        <p onClick={() => navigateToSection('product')}>Product</p>
                        <p onClick={() => navigateToSection('mission')}>Mission</p>
                        <p onClick={() => navigateToSection('values')}>Values</p>
                        <p onClick={() => navigateToSection('contact')}>Contact Us</p>
                    </div>
                </div>
            </div>
            {/* Copyright section */}
            <div className='copyright_container'>
                <h2>© 2024 FINSAT INC.</h2>
            </div>
        </>
    );
}

export default Home;